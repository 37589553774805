import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectClient, selectUser } from 'ui/store/selectors';
import { Store } from 'ui/store/GenericContext';
import { Link } from 'ui/components/Common';
import { hasFeature, hasPermission } from 'ui/utils';
import { Admin } from './Admin';
import { Bar as SplashBar } from './Splash/Bar';
import { Meta as SplashMeta } from './Splash/Meta';
import { Bar as StandardBar } from './Standard/Bar';
import { Meta as StandardMeta } from './Standard/Meta';
import { Drawer as TodosDrawer, Modal as TodosModal } from './Standard/Todos';
import useLayout from './useLayout';
import { todosState } from './Standard/Todos/types';

interface Props {
  restricted?: boolean;
}

export const Header: React.FC<Props> = ({ restricted = false }) => {
  const [showTodos, setShowTodos] = useState(false);
  const { layout, login } = useLayout();
  const fullLayout = layout === 'full';
  const pageLayout = layout === 'page';
  const client = useSelector(selectClient);
  const user = useSelector(selectUser);
  const features = client?.featureSettings;
  const splash = client?.splash;
  let logo: string | undefined;

  if (!client) { return null; }

  if (fullLayout) { logo = client.theme?.dashboardLogo || client.theme?.headerLogo; }
  if (pageLayout) { logo = client.theme?.headerLogo; }

  const headerClasses = (): string => {
    const base: string[] = [];

    if (restricted) {
      if (!login) { base.push('justify-center'); }
      if (login && fullLayout) { base.push('justify-between'); }
      if (login && pageLayout) { base.push('justify-center'); }
    } else {
      base.push('justify-between');
    }

    base.push(fullLayout ? 'items-start h-60 md:h-80' : 'items-center');

    return base.join(' ');
  };

  const renderLogo = () => {
    let justification = restricted ? 'justify-center' : 'justify-start';
    if (login && fullLayout) { justification = 'justify-start'; }
    if (login && pageLayout) { justification = 'justify-center'; }

    return (
      <div className={`flex my-1 md:w-[20%] ${justification}`}>
        {logo && (
          <Link to="/" label="Navigate to Home" className="mr-4 cursor-pointer hidden min-[320px]:block">
            <img
              loading="lazy"
              src={logo}
              alt={client.name}
              className={fullLayout ? 'max-h-60 md:max-h-80' : 'max-h-12 object-scale-down'}
            />
          </Link>
        )}
      </div>
    );
  };

  const standardHeader = () => (
    <React.Fragment>
      <StandardBar layout={layout} />
      <StandardMeta layout={layout} todosState={{ showTodos, setShowTodos }} />
    </React.Fragment>
  );

  const splashHeader = () => {
    if (!splash) { return null; }

    return (
      <React.Fragment>
        <SplashBar layout={layout} />
        <SplashMeta />
      </React.Fragment>
    );
  };

  return (
    <div className="relative flex flex-col items-start no-print">
      {user && !restricted && (hasPermission('admin', user.access) || user.impersonator) && <Admin />}

      <header
        id="primaryNavigation"
        className={`w-full px-[26px] py-2.5 ${fullLayout ? 'bg-transparent' : 'bg-white h-[76px]'}`}
      >
        <div className={`max-w-screen-lg mx-auto flex flex-row ${headerClasses()}`}>
          {renderLogo()}

          {login && hasFeature('splash', features) && splashHeader()}
          {!restricted && standardHeader()}
        </div>
      </header>

      {!!user && (
        <div className={`w-full absolute top-32 ${showTodos ? 'block' : 'invisible'}`}>
          <Store initialState={todosState}>
            <TodosDrawer setShowTodos={setShowTodos} />
            <TodosModal />
          </Store>
        </div>
      )}
    </div>
  );
};

export default Header;
