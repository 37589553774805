/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';
import { useSelector } from 'react-redux';
import { selectTodos, selectFeatures } from 'ui/store/selectors';
import { hasFeature } from 'ui/utils';
import { Messages } from './Messages';
import { Account } from './Account';
import { Button as Todos } from './Todos/Button';
import { Menu } from './Menu';

interface Props {
  layout: string;
  todosState: { showTodos: boolean, setShowTodos: Function };
}

export const Meta: React.FC<Props> = ({ layout, todosState }) => {
  const todos = useSelector(selectTodos);
  const features = useSelector(selectFeatures);
  const { showTodos, setShowTodos } = todosState;

  return (
    <div className="md:w-[20%]">
      <div className="flex gap-4 items-center ml-auto w-fit">
        <Todos count={todos.length} layout={layout} onClick={() => setShowTodos(!showTodos)} />
        {hasFeature('messaging', features) && <Messages layout={layout} />}
        <Account />
        <Menu />
      </div>
    </div>
  );
};

export default Meta;
