import { useSelector } from 'react-redux';
import { pluralize, capitalize } from 'inflection';
import { selectVocabs, selectUser, selectSplash } from 'ui/store/selectors';
import { getVocab } from 'ui/utils';

const useNavigation = () => {
  const vocabs = useSelector(selectVocabs) || {};
  const splash = useSelector(selectSplash);
  const challenge = capitalize(getVocab('challenge', vocabs));
  const user = useSelector(selectUser);

  const navItems = {
    splash: {
      overview: { label: splash?.overviewTitle || 'Overview', path: '#overview' },
      about: { label: splash?.aboutTitle || 'About', path: '#about' },
      contact: { label: splash?.contactTitle || 'Contact', path: '#contact' },
      sponsors: { label: splash?.sponsorTitle || 'Sponsors', path: '#sponsors' },
    },
    home: { label: 'Home', path: '/' },
    planDesign: {
      label: user?.planDesign?.buttonText || 'Program Overview',
      path: user?.planDesign ? `/plan_designs/${user?.planDesign?.id}` : '#',
    },
    account: {
      publicProfile: { label: 'Public Profile', path: `/users/${user?.id}/profile` },
      messageCenter: { label: 'Message Center', path: '/messages' },
      accountSettings: { label: 'Account Settings', path: `/users/${user?.id}/edit` },
      privacySettings: { label: 'Privacy Settings', path: `/users/${user?.id}/privacy_profile/edit` },
      notificationSettings: { label: 'Notification Settings', path: '/notification_settings/edit' },
      language: { label: 'Language', path: '#googleTranslate' },
      support: { label: 'Support', path: '/support' },
      admin: { label: 'Administration', path: '/admin' },
      coaching: { label: 'Coaching Center', path: '/coaching/center' },
      signout: { label: 'Sign Out', path: '/users/sign_out', method: 'delete' },
    },
    challenges: {
      label: pluralize(challenge),
      options: {
        search: { label: `Find ${pluralize(challenge)}`, path: '/challenges' },
        create: { label: `Create a ${challenge}`, path: '/challenges/private_challenges/new' },
        courses: { label: 'Courses', path: '/courses' },
      },
    },
    coaching: { label: 'Coaching', path: '/coaching' },
    events: {
      label: 'Events',
      options: {
        index: { label: 'Find Events', path: '/events' },
        enrolled: { label: 'Enrolled Events', path: '/events/enrolled' },
        past: { label: 'Past Events', path: '/events/past' },
      },
    },
    resources: {
      label: 'Resources',
      options: {
        hubPosts: { label: 'Resource Hub', path: '/posts' },
        articles: { label: 'Articles', path: '/articles' },
        recipes: { label: 'Recipes', path: '/recipes' },
        workouts: { label: 'Workouts', path: '/workouts' },
        favorites: { label: 'Favorites', path: '/favorites' },
        mealPlans: { label: 'Meal Planner', path: '/meal_plans' },
      },
    },
    navigateYou: { label: 'NavigateYOU', path: '/navigateyou' },
    photos: { label: 'Photo Gallery', path: '/photos' },
    socialWall: { label: 'Social Wall', path: '/social_wall' },
    tracker: { label: 'Tracker', path: '/tracker' },
    rewards: { label: 'Rewards', path: '/rewards' },
  };

  return navItems;
};

export default useNavigation;
