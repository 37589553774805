import React, { useState, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useSelector, useDispatch } from 'react-redux';
import { selectTodos } from 'ui/store/selectors';
import { replaceTodos } from 'ui/store/reducers/todosReducer';
import { apiClient } from 'ui/utils';
import { Carousel } from 'ui/components/Common';
import { Spinner, Times } from 'ui/components/Icons';
import { useStore } from 'ui/store/GenericContext';
import { Todo as TodoType } from 'ui/types';
import { Todo } from './Todo';
import { TodosType } from './types';

interface Props {
  setShowTodos: React.Dispatch<React.SetStateAction<boolean>>;
}

export const Drawer: React.FC<Props> = ({ setShowTodos }) => {
  const [updating, setUpdating] = useState<boolean>(false);
  const reduxDispatch = useDispatch();
  const todos = useSelector(selectTodos);
  const { state, dispatch } = useStore<TodosType, any>();

  let carousel: { per: number, spacing: number } = { per: 6, spacing: 20 };
  if (useMediaQuery({ query: '(max-width: 1440px)' })) { carousel = { per: 4, spacing: 20 }; }
  if (useMediaQuery({ query: '(max-width: 1024px)' })) { carousel = { per: 3, spacing: 20 }; }

  const fetchTodo = async (todo: TodoType): Promise<TodoType | null> => {
    try {
      const res = await apiClient.get(`todos/${todo.id}`);
      return res.data as TodoType;
    } catch (error) {
      return null;
    }
  };

  useEffect(() => {
    if (!state || !state.update) { return; }

    const updateTodos = async () => {
      setUpdating(true);
      const todosToUpdate = todos.filter(todo => todo.itemType === state.update);
      const completed = (await Promise.all(todosToUpdate.map(fetchTodo))).filter(todo => todo?.completed);
      const completedIds = completed.map(todo => todo!.id);
      const updated = todos.filter(todo => todo.itemType !== state.update || !completedIds.includes(todo.id));
      reduxDispatch(replaceTodos(updated));
      setUpdating(false);
    };

    updateTodos();
    dispatch({ type: 'SET_KEY', key: 'update', payload: null });
  }, [state.update]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <section className="relative w-full">
      <div className="absolute z-10 w-full px-6 pt-4 pb-1 flex flex-col mx-auto shadow-sm bg-light-gray">
        <div className="flex justify-between items-center mb-4">
          <div className="flex gap-3 items-center">
            <div className="text-lg">Today&apos;s To-Do List</div>
            {updating && <Spinner width="w-6" height="h-6" />}
          </div>

          <button type="button" title="Cancel" onClick={() => setShowTodos(false)}>
            <Times width="w-[20px]" height="h-[20px]" />
          </button>
        </div>

        {todos.length === 0 && (
          <div>No To-Dos Available</div>
        )}

        {todos.length > 0 && (
          <Carousel perView={carousel.per} spacing={carousel.spacing}>
            {todos.map((todo: TodoType) => (
              <Todo key={todo.id} todo={todo} />
            ))}
          </Carousel>
        )}
      </div>
    </section>
  );
};

export default Drawer;
