/* eslint-disable react/no-array-index-key */
import React, { useEffect } from 'react';
import { pluralize, capitalize } from 'inflection';
import { useSelector } from 'react-redux';
import { Link } from 'ui/components/Common';
import { selectClient, selectSplash, selectUserPlanDesign } from 'ui/store/selectors';
import { hasFeature, allFeatures, getVocab } from 'ui/utils';
import { FooterColumn } from './FooterColumn';
import { FooterItem } from './FooterItem';
import useLayout from '../Header/useLayout';

interface Props {
  restricted?: boolean;
}

export const Footer: React.FC<Props> = ({ restricted = false }) => {
  const { login } = useLayout();
  const client = useSelector(selectClient)!;
  const splash = useSelector(selectSplash);
  const planDesign = useSelector(selectUserPlanDesign);
  const { address } = client.clientSettings;

  useEffect(() => {
    if (!hasFeature('translation', client.featureSettings)) { return; }

    if (!(window as any).googleTranslateElementInit) {
      const script = document.createElement('script');
      script.src = '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';
      script.async = true;

      const googleTranslateElementInit = () => {
        (window as any).google.translate.TranslateElement({
          pageLanguage: 'en',
          autoDisplay: false,
        }, 'googleTranslate');
      };

      (window as any).googleTranslateElementInit = googleTranslateElementInit;
      document.body.appendChild(script);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const renderLegal = () => (
    <div className="flex flex-col xs:flex-row gap-5 self-start mt-16 text-sm text-secondary font-light">
      <div>©{new Date().getFullYear()} <span className="skiptranslate">{client.name}</span></div>
      <Link to="/terms_of_service">Terms of Service</Link>
      <Link to="/privacy_policy">Privacy Policy</Link>

      {!!client.ginaLink && (
        <Link to="/gina">Notice Regarding Wellness Program</Link>
      )}
    </div>
  );

  if (login && hasFeature('splash', client.featureSettings) && !!splash?.footerContent) {
    return (
      <footer className="relative bg-primary p-7 flex flex-col justify-center no-print">
        <div className="text-secondary mb-10" dangerouslySetInnerHTML={{ __html: splash.footerContent }} />

        {client.joinable && (
          <Link to="/users/sign_up" className="mx-auto w-[254px] btn bg-secondary border-secondary text-primary text-center">
            Join Now
          </Link>
        )}

        {hasFeature('translation', client.featureSettings) && (
          <div id="googleTranslate" className="no-twp mx-auto mt-3 md:mt-0 md:absolute bottom-5 right-5" />
        )}

        {renderLegal()}
      </footer>
    );
  }

  return (
    <footer className="bg-primary p-7 flex flex-col justify-center no-print">
      <div className="flex flex-wrap">
        <div className="w-[50%] mb-10">
          {client.theme?.footerLogo && (
            <Link to="/" label="Navigate to Home" className="inline-block">
              <img
                loading="lazy"
                src={client.theme?.footerLogo}
                alt={client.name}
                className="w-[200px]"
              />
            </Link>
          )}
        </div>

        <div className="flex flex-col flex-grow pr-20">
          <div className="flex flex-col gap-5 sm:flex-row sm:gap-10 justify-end">
            {!restricted && (
              <React.Fragment>
                <FooterColumn text="Explore">
                  {!!planDesign && planDesign.buttonEnabled && <FooterItem text={planDesign.buttonText} path={`/plan_designs/${planDesign.id}`} />}

                  {allFeatures(['challenges', 'challengeSearch'], client.featureSettings) && (
                    <FooterItem text={`${pluralize(capitalize(getVocab('challenge', client.vocab)))}`} path="/challenges" />
                  )}

                  {allFeatures(['challengeSearch', 'courses'], client.featureSettings) && (
                    <FooterItem text="Courses" path="/courses" />
                  )}
                  <FooterItem text="Resources" path="/posts" />
                  {hasFeature('rewards', client.featureSettings) && <FooterItem text="Rewards" path="/rewards" />}
                </FooterColumn>

                <FooterColumn text="Support">
                  <FooterItem text="Download App" path="/mobile_apps" />
                  <FooterItem text="Sync Device" path="/devices" />
                  <FooterItem text="Contact" path="/support" />
                </FooterColumn>
              </React.Fragment>
            )}

            <FooterColumn text="Contact">
              <div className="skiptranslate">
                <FooterItem text={client.name} />
                <FooterItem text={client.clientSettings.phone} />
                <FooterItem text={client.clientSettings.email} />

                {address && (
                  <div className="text-secondary mt-5">
                    {address.street && <div>{address.street}</div>}
                    {address.street2 && <div>{address.street2}</div>}
                    <div>
                      {address.city && <span>{address.city}</span>}
                      {address.city && address.state && <span>, </span>}
                      {address.state && <span>{address.state}</span>}
                      {address.zip && <span>&nbsp;{address.zip}</span>}
                    </div>
                  </div>
                )}
              </div>

              {hasFeature('translation', client.featureSettings) && (
                <div id="googleTranslate" className="no-twp" />
              )}
            </FooterColumn>
          </div>
        </div>
      </div>

      {renderLegal()}
    </footer>
  );
};

export default Footer;
